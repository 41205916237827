import React from "react";
import { AppBtn } from "../../ui/buttons";
import Modal, { ModalBody, ModalFooter } from "../../ui/modal";
import { InputField, PhoneInput } from "@/components/ui/form-elements";
import { useFormik } from "formik";
import { getFieldvalues, phoneObjectToString } from "@/assets/js/utils/functions";
import { useRequest } from "@/api/utils";
import { PublicCreateCustomerParams } from "@/api/interfaces/orders-customers.interface";
import { CreateCustomerPublic } from "@/api/orders-customers";
import { CheckoutForm } from "./checkout";
import CartContext from "@/contexts/cart-context";

interface Props {
  show: boolean;
  toggle: (state: boolean) => void;
}

interface CustomerDetails {
  name: string;
  phone: {
    code: string;
    digits: string;
  };
  email?: string;
}

const CustomerCartInfo: React.FC<Props> = ({ show, toggle }) => {
  const { storeId, setCustomerId, customerDetailsCache, setCustomerDetailsCache, currencies } =
    CartContext.useContainer();

  const createCustomerReq = useRequest<PublicCreateCustomerParams>(CreateCustomerPublic);

  const form = useFormik<CustomerDetails>({
    initialValues: {
      name: "",
      phone: {
        code: "+234",
        digits: "",
      },
    },
    onSubmit: () => {
      createCustomer(form.values);
    },
  });

  const createCustomer = async (values: CustomerDetails) => {
    const [res, err] = await createCustomerReq.makeRequest({
      store: storeId,
      phone: phoneObjectToString(values.phone),
      name: values.name,
    });

    if (res) {
      setCustomerId(res.data.id);
      setCustomerDetailsCache({
        ...customerDetailsCache,
        name: values.name,
        phone: values.phone,
        currency: currencies.active,
        customer_id: res.data.id,
      });
      toggle(false);
    }
  };

  return (
    <Modal {...{ show, toggle }} title="Cart Information" closeable={false} bgClose={false} size="midi">
      {/* <form className="flex flex-col flex-auto overflow-hidden" onSubmit={form.handleSubmit}> */}
      <ModalBody>
        <div>
          <div className="flex items-center flex-col mt-3">
            <figure className="h-16 w-16 sm:h-20 sm:w-20 rounded-full flex items-center justify-center bg-primary-500">
              {/* prettier-ignore */}
              <svg className="w-1/2 text-white" viewBox="0 0 24 24" fill="none">
              <path d="M19.24 5.57859H18.84L15.46 2.19859C15.19 1.92859 14.75 1.92859 14.47 2.19859C14.2 2.46859 14.2 2.90859 14.47 3.18859L16.86 5.57859H7.14L9.53 3.18859C9.8 2.91859 9.8 2.47859 9.53 2.19859C9.26 1.92859 8.82 1.92859 8.54 2.19859L5.17 5.57859H4.77C3.87 5.57859 2 5.57859 2 8.13859C2 9.10859 2.2 9.74859 2.62 10.1686C2.86 10.4186 3.15 10.5486 3.46 10.6186C3.75 10.6886 4.06 10.6986 4.36 10.6986H19.64C19.95 10.6986 20.24 10.6786 20.52 10.6186C21.36 10.4186 22 9.81859 22 8.13859C22 5.57859 20.13 5.57859 19.24 5.57859Z" fill="currentColor"/>
              <path d="M19.0897 12H4.90971C4.28971 12 3.81971 12.55 3.91971 13.16L4.75971 18.3C5.03971 20.02 5.78971 22 9.11971 22H14.7297C18.0997 22 18.6997 20.31 19.0597 18.42L20.0697 13.19C20.1897 12.57 19.7197 12 19.0897 12ZM14.8797 16.05L11.6297 19.05C11.4897 19.18 11.3097 19.25 11.1197 19.25C10.9297 19.25 10.7397 19.18 10.5897 19.03L9.08971 17.53C8.79971 17.24 8.79971 16.76 9.08971 16.47C9.38971 16.18 9.85971 16.18 10.1597 16.47L11.1497 17.46L13.8697 14.95C14.1697 14.67 14.6497 14.69 14.9297 14.99C15.2097 15.3 15.1897 15.77 14.8797 16.05Z" fill="currentColor"/>
            </svg>
            </figure>
            <h2 className="text-black text-2lg sm:text-2xl font-semibold mt-4 text-center leading-tight">
              Provide your details <br /> to save this Cart
            </h2>
          </div>
          <InputField label="Name" {...getFieldvalues("name", form)} />
          <PhoneInput label="Phone" {...getFieldvalues("phone", form)} />
        </div>
      </ModalBody>
      <ModalFooter>
        <AppBtn isBlock size="lg" disabled={createCustomerReq?.isLoading} onClick={() => form.handleSubmit()}>
          {createCustomerReq?.isLoading ? "Loading..." : "Continue Shopping"}
        </AppBtn>
      </ModalFooter>
      {/* </form> */}
    </Modal>
  );
};

export default CustomerCartInfo;
